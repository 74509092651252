import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="reset-form"
export default class extends Controller {
  reset() {
    // Réinitialiser le formulaire
    this.element.reset()

    // Supprimer les erreurs des champs
    this.clearErrors()
  }

  clearErrors() {
    // Sélectionner tous les éléments avec la classe `is-invalid`
    this.element.querySelectorAll(".is-invalid").forEach((input) => {
      input.classList.remove("is-invalid")
    })

    // Cacher tous les messages d'erreur
    this.element.querySelectorAll(".invalid-feedback").forEach((errorMessage) => {
      errorMessage.style.display = "none"
    })
  }
}
