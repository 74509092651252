import { Controller } from "@hotwired/stimulus"
import Swal from "sweetalert2"
import i18n from "../helpers/i18n_config";

export default class extends Controller {
  static targets = [
    "firstName", "lastName", "birthday", "birthCity", "nationality", "occupation", "address", "zipCode", "city",
    "coBuyerFirstName", "coBuyerLastName", "coBuyerBirthday", "coBuyerBirthCity", "coBuyerNationality", "coBuyerOccupation",
    "weddingCity", "weddingDate", "matrimonialRegime", "pacsType", "pacsDate", "pacsTypeOfRecord", "matrimonialCourtOfficeCity",
    "matrimonialNotaryName", "exPartnerFirstName", "exPartnerLastName", "divorceDate", "guiardianshipJudgeCity",
    "guiardianshipJudgementDate", "abilityActingAlone", "representativeFirstName", "representativeLastName",
    "representativeBirthday", "representativeBirthCity", "representativeAddress", "representativeZipCode",
    "representativeCity", "curatorshipGuardianshipStatus","notaryFees", "otherCost", "otherCostList", "acquisitionType", "acquisitionTypeList",
    "financingSimulation", "financingSimulationDiv", "condominium", "buildBefore_01_01_1949", "buildingPermitBefore_01_07_1997", "sanitation",
    "exposedRisksPollution", "electricalInstallationMore_15_year", "gasInstallationMore_15_year", "lossDueToNaturalOrTechnologicalDisaster",
    "noiseExposure", "dryRotRiskArea", "termiteRisk", "clayArea", "landTypeSubdivision", "conclusionsOfTechnicalDiagnosis", "overallStatementOfUnpaid",
    "amountsLikelyToBeOwed", "amountsOfTheWorkFund", "rightsOfWay", "rightsOfWayList", "rentalStatus", "tenantInformations",
    "cadastralTemplate", "addCastralButton", "maritalLegalStatus", "landType", "plotDivs"
  ];

  static userFormTargets = [
    "firstName", "lastName", "birthday", "birthCity", "nationality", "occupation", "address", "zipCode", "city",
    "coBuyerFirstName", "coBuyerLastName", "coBuyerBirthday", "coBuyerBirthCity", "coBuyerNationality", "coBuyerOccupation",
    "weddingCity", "weddingDate", "matrimonialRegime", "pacsType", "pacsDate", "pacsTypeOfRecord", "matrimonialCourtOfficeCity",
    "matrimonialNotaryName", "exPartnerFirstName", "exPartnerLastName", "divorceDate", "guiardianshipJudgeCity",
    "guiardianshipJudgementDate", "abilityActingAlone", "representativeFirstName", "representativeLastName",
    "representativeBirthday", "representativeBirthCity", "representativeAddress", "representativeZipCode",
    "representativeCity", "curatorshipGuardianshipStatus"
  ];

  connect() {
    this.toggleRightOfWayDiv();
    this.toggleRentalStatusDiv();
    this.userFormVisibility();
    this.toggleLandTypeDiv();
    this.toggleOtherCostDiv();
    this.toggleAcquisitionTypeDiv();
    this.toggleFinancingSimulationDiv();
  }

  disconnect() {
  }

  // User
  userFormVisibility() {
    if (this.hasMaritalLegalStatusTarget) {
      // Masquer tous les targets au départ
      this.hideAllUserFormTargets();

      // Afficher uniquement les champs correspondant à la sélection
      const targetsToShow = this.getTargetsUserToShow(this.maritalLegalStatusTarget.value);
      targetsToShow.forEach((targetName) => {
        const target = this[`${targetName}Target`];
        if (target) {
          target.classList.remove('d-none');
        }
      });

      this.changePacsTypeOfRecord();
      this.toggleRepresentativeDiv();
    }
  }

  hideAllUserFormTargets() {
    // Masquer tous les targets relatifs au formulaire utilisateur
    this.constructor.userFormTargets.forEach((targetName) => {
      if (this[`${targetName}Target`]) {
        const target = this[`${targetName}Target`];
        target.classList.add('d-none');
      }
    });
  }

  getTargetsUserToShow(maritalLegalType) {
    const mappings = {
      'single': ['firstName', 'lastName', 'birthday', 'birthCity', 'nationality', 'occupation', 'address', 'zipCode', 'city'],
      'married_couple_acting_alone': ['firstName', 'lastName', 'birthday', 'birthCity', 'nationality', 'occupation', 'address', 'zipCode', 'city', 'coBuyerFirstName', 'coBuyerLastName', 'weddingCity', 'weddingDate', 'matrimonialRegime'],
      'married_couple_acting_jointly': ['firstName', 'lastName', 'birthday', 'birthCity', 'nationality', 'occupation', 'address', 'zipCode', 'city', 'coBuyerFirstName', 'coBuyerLastName', 'coBuyerBirthday', 'coBuyerBirthCity', 'coBuyerNationality', 'coBuyerOccupation', 'weddingCity', 'weddingDate', 'matrimonialRegime'],
      'pacs_couple_acting_alone': ['firstName', 'lastName', 'birthday', 'birthCity', 'nationality', 'occupation', 'address', 'zipCode', 'city', 'coBuyerFirstName', 'coBuyerLastName', 'pacsType', 'pacsDate', 'pacsTypeOfRecord', 'matrimonialCourtOfficeCity', 'matrimonialNotaryName'],
      'pacs_couple_acting_jointly': ['firstName', 'lastName', 'birthday', 'birthCity', 'nationality', 'occupation', 'address', 'zipCode', 'city', 'coBuyerFirstName', 'coBuyerLastName', 'coBuyerBirthday', 'coBuyerBirthCity', 'coBuyerNationality', 'coBuyerOccupation', 'pacsType', 'pacsDate', 'pacsTypeOfRecord', 'matrimonialCourtOfficeCity', 'matrimonialNotaryName'],
      'divorced_person': ['firstName', 'lastName', 'birthday', 'birthCity', 'nationality', 'occupation', 'address', 'zipCode', 'city', 'exPartnerFirstName', 'exPartnerLastName', 'divorceDate'],
      'widowed_person': ['firstName', 'lastName', 'birthday', 'birthCity', 'nationality', 'occupation', 'address', 'zipCode', 'city', 'exPartnerFirstName', 'exPartnerLastName'],
      'person_under_legal_protection': ['firstName', 'lastName', 'birthday', 'birthCity', 'nationality', 'occupation', 'address', 'zipCode', 'city', 'guiardianshipJudgeCity', 'guiardianshipJudgementDate', 'abilityActingAlone', 'representativeFirstName', 'representativeLastName', 'representativeBirthday', 'representativeBirthCity', 'representativeAddress', 'representativeZipCode', 'representativeCity'],
      'person_under_curatorship_or_guardianship': ['firstName', 'lastName', 'birthday', 'birthCity', 'nationality', 'occupation', 'address', 'zipCode', 'city', 'guiardianshipJudgeCity', 'guiardianshipJudgementDate', 'abilityActingAlone', 'curatorshipGuardianshipStatus', 'representativeFirstName', 'representativeLastName', 'representativeBirthday', 'representativeBirthCity', 'representativeAddress', 'representativeZipCode', 'representativeCity'],
    };

    return mappings[maritalLegalType] || [];
  }

  changePacsTypeOfRecord() {
    const selectedType = this.pacsTypeOfRecordTarget.querySelector('#maritalLegalPacsDiv').value;
    const elements = {
      'judicial_court_registry': ['block', 'none'],
      'notary': ['none', 'block'],
      'default': ['none', 'none']
    };
    const [courtDisplay, notaryDisplay] = elements[selectedType] || elements['default'];

    this.matrimonialCourtOfficeCityTarget.style.display = courtDisplay;
    this.matrimonialNotaryNameTarget.style.display = notaryDisplay;
  }

  toggleRepresentativeDiv() {
    const selectedValue = this.abilityActingAloneTarget.querySelector('#personAbilityDiv').value;
    const representativeDiv = document.getElementById('representativeDiv');

    representativeDiv.style.display = (selectedValue === 'false') ? 'block' : 'none';
  }

  // Description
  toggleLandTypeDiv() {
    if (this.hasLandTypeTarget) {
      if (this.landTypeTarget.value === "subdivision") {
        this.plotDivsTarget.classList.remove("d-none");
        this.plotDivsTarget.removeAttribute('hidden');
      } else {
        this.plotDivsTarget.classList.add("d-none");
        this.plotDivsTarget.setAttribute('hidden', 'true');
      }
    }
  }

  addCadastral(event) {
    event.preventDefault()
    const content = this.cadastralTemplateTarget.innerHTML.replace(/TEMPLATE_RECORD/g, Math.floor(Math.random() * 20))
    this.addCastralButtonTarget.insertAdjacentHTML('beforebegin', content)
    this.descriptionFormVisibility();
  }

  removeCadastral(event) {
    event.preventDefault()
    let item = event.target.closest(".nested-fields")
    const destroyInput = item.querySelector("input[name*='_destroy']")
    destroyInput.value = 1
    item.querySelectorAll('input').forEach((input) => {
      if (input === destroyInput) return;
      input.remove()
      item.style.display = "none"
    });
  }

  // Conditions
  toggleRightOfWayDiv() {
    if (this.hasRightsOfWayTarget) {
      if (this.rightsOfWayTarget.value === "true") {
          this.rightsOfWayListTarget.classList.remove('d-none');
          this.rightsOfWayListTarget.removeAttribute('hidden');
      } else {
          this.rightsOfWayListTarget.classList.add('d-none');
          this.rightsOfWayListTarget.setAttribute('hidden', 'true');
      }
    }
  }

  toggleRentalStatusDiv() {
    if (this.hasRentalStatusTarget) {
      if (this.rentalStatusTarget.value === "occupied") {
          this.tenantInformationsTarget.classList.remove('d-none');
          this.tenantInformationsTarget.removeAttribute('hidden');
      } else {
          this.tenantInformationsTarget.classList.add('d-none');
          this.tenantInformationsTarget.setAttribute('hidden', 'true');
      }
    }
  }

  // Funding
  toggleOtherCostDiv() {
    if (this.hasOtherCostTarget) {
      if (this.otherCostTarget.value === "true") {
          this.otherCostListTarget.classList.remove('d-none');
          this.otherCostListTarget.removeAttribute('hidden');
      } else {
          this.otherCostListTarget.classList.add('d-none');
          this.otherCostListTarget.setAttribute('hidden', 'true');
      }
    }
  }

  toggleAcquisitionTypeDiv() {
    if (this.hasAcquisitionTypeTarget) {
      if (this.acquisitionTypeTarget.value === "funding") {
          this.acquisitionTypeListTarget.classList.remove('d-none');
          this.acquisitionTypeListTarget.removeAttribute('hidden');
      } else {
          this.acquisitionTypeListTarget.classList.add('d-none');
          this.acquisitionTypeListTarget.setAttribute('hidden', 'true');
      }
    }
  }

  toggleFinancingSimulationDiv() {
    if (this.hasFinancingSimulationTarget) {
      if (this.financingSimulationTarget.value === "true") {
          this.financingSimulationDivTarget.classList.remove('d-none');
          this.financingSimulationDivTarget.removeAttribute('hidden');
      } else {
          this.financingSimulationDivTarget.classList.add('d-none');
          this.financingSimulationDivTarget.setAttribute('hidden', 'true');
      }
    }
  }
}
