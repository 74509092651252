import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="scroll"
export default class extends Controller {
  static values = {
    targetId: String,
    offset: Number,
    passToNextStep: Boolean
  };

  connect() {
    this.scrollToTarget();
  }

  scrollToTarget() {
    if (this.hasPassToNextStepValue && this.passToNextStepValue) {
      const nextStep = document.getElementById('scrollspyNotaryChoice') || document.getElementById('scrollspyLawyerChoice');
      const yOffset = -90;
      const y = nextStep.getBoundingClientRect().top + window.scrollY + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    } else {
      const targetElement = document.getElementById(this.targetIdValue);
      if (targetElement) {
        const yOffset = this.hasOffsetValue ? this.offsetValue : -90;
        const y = targetElement.getBoundingClientRect().top + window.scrollY + yOffset;
        window.scrollTo({ top: y, behavior: 'smooth' });
      }
    }
  }
}
