import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "accountType",
    "sci",
    "acquisitionType",
    "dossierFacileType",
    "loan",
    "dossierFacile",
    "financingSimulation",
    "simulation"
  ]

  connect() {
    this.changeAccountType();
    this.changeAcquisitionType();
    this.changeDossierFacileType();
    this.changeFinancingSimulation();
  }

  changeAccountType() {
    if (this.hasAccountTypeTarget) {
      if (this.accountTypeTarget.value === "company") {
        this.sciTarget.classList.remove("d-none");
      } else {
        this.sciTarget.classList.add("d-none");
      }
    }
  }

  changeAcquisitionType() {
    if (this.hasAcquisitionTypeTarget) {
      if (this.acquisitionTypeTarget.value === "funding") {
        this.loanTarget.classList.remove("d-none");
      } else {
        this.loanTarget.classList.add("d-none");
      }
    }
  }

  changeDossierFacileType() {
    if (this.hasDossierFacileTypeTarget) {
      if (this.dossierFacileTypeTarget.value === "with_dossier_facile") {
        this.dossierFacileTarget.classList.remove("d-none");
      } else {
        this.dossierFacileTarget.classList.add("d-none");
      }
    }
  }

  changeFinancingSimulation() {
    if (this.hasFinancingSimulationTarget) {
      if (this.financingSimulationTarget.value === "true") {
        this.simulationTarget.classList.remove("d-none");
      } else {
        this.simulationTarget.classList.add("d-none");
      }
    }
  }
}
