import { Controller } from "@hotwired/stimulus"
import i18n from "../helpers/i18n_config";
import AutoNumeric from "autonumeric";

// Connects to data-controller="auto-numeric"
export default class extends Controller {
  connect() {
    setTimeout(() => {
      const decimalPlaces = this.element.dataset.decimalPlaces || 0;
      const maximumValue = this.element.dataset.maximumValue || 0;

      const options = {
        digitGroupSeparator: i18n.t('number.currency.format.delimiter'),
        decimalCharacter: i18n.t('number.currency.format.separator'),
        decimalPlaces: parseInt(decimalPlaces), // Utiliser le nombre de décimales spécifié
        minimumValue: "0",
        maximumValue: parseInt(maximumValue),
        modifyValueOnWheel: false,
        negativeSignCharacter: "-",
        negativeBracketsTypeOnBlur: null,
        suffixText: "",
      };

      this.autoNumeric = new AutoNumeric(this.element, options);

      // Bind the method to the form submit event
      this.element.closest('form').addEventListener('submit', this.formatBeforeSubmit.bind(this));
    }, 500);
  }

  disconnect() {
    if (this.autoNumeric) {
      this.clearValue();
      this.autoNumeric.remove();
    }
  }

  formatBeforeSubmit(event) {
    if (this.autoNumeric) {
      // Deformat the value if it's not empty
      if (this.element.value !== '') {
        const rawValue = this.autoNumeric.getNumber();
        this.element.value = rawValue;
      }
    }
  }

  clearValue() {
    if (this.autoNumeric) {
      this.autoNumeric.clear();
    }
  }
}
