import * as bootstrap from "bootstrap";

// Initialiser les composants Bootstrap
export function initializeBootstrapComponents() {
  // Initialisation des tooltips
  const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
  if (tooltipTriggerList.length > 0) {
    tooltipTriggerList.forEach(tooltipTriggerEl => {
      let oldTooltip = bootstrap.Tooltip.getInstance(tooltipTriggerEl);
      if (oldTooltip) {
        oldTooltip.dispose();
      }
      let newTooltip = new bootstrap.Tooltip(tooltipTriggerEl, {
        html: true,
        customClass: tooltipTriggerEl.getAttribute('data-bs-custom-class') || ''
      });

      const closeTooltipOnScroll = () => {
        newTooltip.hide();
        window.removeEventListener('scroll', closeTooltipOnScroll);
        window.removeEventListener('touchmove', closeTooltipOnScroll);
      };

      tooltipTriggerEl.addEventListener('shown.bs.tooltip', () => {
        window.addEventListener('scroll', closeTooltipOnScroll);
        window.addEventListener('touchmove', closeTooltipOnScroll);
      });

      tooltipTriggerEl.addEventListener('hidden.bs.tooltip', () => {
        window.removeEventListener('scroll', closeTooltipOnScroll);
        window.removeEventListener('touchmove', closeTooltipOnScroll);
      });
    });
  }

  // Initialisation des popovers
  const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]');
  popoverTriggerList.forEach(popoverTriggerEl => {
    let oldPopover = bootstrap.Popover.getInstance(popoverTriggerEl);
    if (oldPopover) {
      oldPopover.dispose();
    }
    new bootstrap.Popover(popoverTriggerEl, {
      html: true,
      trigger: 'focus'
    });
  });
}
