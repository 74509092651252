import { Controller } from "@hotwired/stimulus";
import flatpickr from "flatpickr";
import { French } from "flatpickr/dist/l10n/fr.js";
import { english } from "flatpickr/dist/l10n/default.js"; // Importer l'anglais par défaut
import { Spanish } from "flatpickr/dist/l10n/es.js"; // Importer l'espagnol

export default class extends Controller {
  connect() {
    // Vérifier si Flatpickr est déjà initialisé et le détruire s'il l'est
    if (this.element._flatpickr) {
      this.element._flatpickr.destroy();
    }

    // Déterminer la locale actuelle de l'application
    const currentLocale = document.documentElement.lang;

    // Définir la configuration de Flatpickr
    const localeMapping = {
      'fr-FR': French,
      'en-US': english,
      'es': Spanish
    };

    // Définir les formats de date pour chaque locale
    const dateFormatMapping = {
      'fr-FR': "d/m/Y",
      'en-US': "m/d/Y",
      'es': "d/m/Y"
    };

    // Récupérer la valeur actuelle de l'élément
    const initialDate = this.element.value;

    this.flatpickrInstance = new flatpickr(this.element, {
      locale: localeMapping[currentLocale] || French, // Par défaut, utiliser le français si la locale n'est pas trouvée
      dateFormat: "Y-m-d", // Format de date stocké dans la base de données
      altInput: true, // Utiliser altInput pour afficher le altFormat
      altFormat: dateFormatMapping[currentLocale] || "d/m/Y", // Format de date affiché
      defaultDate: initialDate || null, // Utiliser la date initiale si disponible
    });
  }

  disconnect() {
    if (this.flatpickrInstance) {
      this.flatpickrInstance.destroy();
    }
  }
}
