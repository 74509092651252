import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="message-menu"
export default class extends Controller {
  static targets = ["button", "message"];

  connect() {
    // Récupère l'URL actuelle
    const url = window.location.href;

    // Détermine quel onglet activer en fonction de l'URL
    if (url.includes("work_message")) {
      this.activateTab("work");
    } else if (url.includes("transaction_message")) {
      this.activateTab("transaction");
    }
  }

  activateTab(buttonId) {
    const currentButton = this.buttonTargets.find(
      target => target.getAttribute("data-button-id") === buttonId
    );
    if (currentButton) {
      currentButton.classList.add("text-success");
    }
    const correspondingMessage = this.messageTargets.find(
      target => target.getAttribute("data-message-id") === buttonId
    );
    if (correspondingMessage) {
      correspondingMessage.classList.remove("d-none");
    }
  }
}
