import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="select-placeholder"
export default class extends Controller {
  static targets = ['select'];

  connect() {
    this.selectTarget.classList.add('initial-prompt');
  }

  hidePrompt() {
    const promptOption = this.element.querySelector('.prompt-option');
    if (promptOption && promptOption.classList.contains('initial-prompt')) {
      promptOption.style.display = 'none';
      promptOption.classList.remove('initial-prompt');
    }
  }
}
