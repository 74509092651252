import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "notaryFees",
    "totalAcquisitionCost",
    "financingSection",
    "obtainFinancing",
    "desiredSellingPrice",
    "realEstateAgencyFees",
    "obtainTownPlanningCertificate",
    "certificateSection",
    "obtainBuildingPermit",
    "permitSection"
  ];

  connect() {
    this.changeObtainFinancing();
    this.changeObtainTownPlanningCertificate();
    this.changeObtainBuildingPermit();
  }

  changeObtainFinancing() {
    if (this.hasObtainFinancingTarget) {
      if (this.obtainFinancingTarget.value === 'true') {
        this.financingSectionTarget.classList.remove("d-none");
        this.financingSectionTarget.removeAttribute('hidden');
      } else {
        this.financingSectionTarget.classList.add("d-none");
        this.financingSectionTarget.setAttribute('hidden', 'true');
      }
    }
  }

  changeObtainTownPlanningCertificate() {
    if (this.hasObtainTownPlanningCertificateTarget) {
      if (this.obtainTownPlanningCertificateTarget.value === 'true') {
        this.certificateSectionTarget.classList.remove("d-none");
        this.certificateSectionTarget.removeAttribute('hidden');
      } else {
        this.certificateSectionTarget.classList.add("d-none");
        this.certificateSectionTarget.setAttribute('hidden', 'true');
      }
    }
  }

  changeObtainBuildingPermit() {
    if (this.hasObtainBuildingPermitTarget) {
      if (this.obtainBuildingPermitTarget.value === 'true') {
        this.permitSectionTarget.classList.remove("d-none");
        this.permitSectionTarget.removeAttribute('hidden');
      } else {
        this.permitSectionTarget.classList.add("d-none");
        this.permitSectionTarget.setAttribute('hidden', 'true');
      }
    }
  }

  setTotalCost() {
    const netSellingPriceInput = this.desiredSellingPriceTarget;
    let netSellingPriceInputValue = netSellingPriceInput.value || 0;

    // Initialiser les variables pour les frais d'agence
    let realEstateAgencyFeesInputValue = "";

    // Vérifier si la cible realEstateAgencyFeesTarget est présente
    if (this.hasRealEstateAgencyFeesTarget) {
      const realEstateAgencyFeesInput = this.realEstateAgencyFeesTarget;
      realEstateAgencyFeesInputValue = realEstateAgencyFeesInput.value || "";

      // Nettoyer la valeur pour garder seulement les chiffres et le point
      realEstateAgencyFeesInputValue = realEstateAgencyFeesInputValue.toString().replace(/[^0-9.]/g, '');
    }

    // Nettoyer la valeur de netSellingPriceInputValue
    netSellingPriceInputValue = netSellingPriceInputValue.toString().replace(/[^0-9.]/g, '');

    // Vérifier si la valeur saisie est un nombre
    if (!netSellingPriceInputValue || isNaN(netSellingPriceInputValue)) {
      return; // Sortir si le champ est vide ou non valide
    }

    // Appel à l'API pour calculer les coûts
    fetch(`set_total_cost?net_selling_price=${netSellingPriceInputValue}&real_estate_agency_fees=${realEstateAgencyFeesInputValue || 0}`, {
      method: "GET",
      headers: { "Accept": "application/json" },
    })
    .then(response => response.json())
    .then((data) => {
      // Formater et afficher les valeurs obtenues
      netSellingPriceInput.value = parseInt(netSellingPriceInputValue, 10).toLocaleString('fr-FR').replace(',', ' ');

      // Si realEstateAgencyFeesTarget est présent, gérer son affichage
      if (this.hasRealEstateAgencyFeesTarget) {
        if (realEstateAgencyFeesInputValue) {
          this.realEstateAgencyFeesTarget.value = parseInt(realEstateAgencyFeesInputValue, 10).toLocaleString('fr-FR').replace(',', ' ');
        } else {
          this.realEstateAgencyFeesTarget.value = ''; // Effacer la valeur pour afficher le placeholder
        }
      }

      // Afficher les valeurs dans les autres cibles
      this.notaryFeesTarget.value = data.notary_cost.toLocaleString('fr-FR').replace(',', ' ');
      this.totalAcquisitionCostTarget.value = data.total_cost.toLocaleString('fr-FR').replace(',', ' ');
    });
  }

  filterNumericInput(event) {
    const regex = /^[0-9]*\.?[0-9]*$/;
    if (!regex.test(event.target.value)) {
      event.target.value = event.target.value.replace(/[^0-9\.]/g, '');
    }
  }

  setCurrency(event) {
    const desiredDepositInput = event.currentTarget;
    if (desiredDepositInput.value.trim() === '') {
      desiredDepositInput.value = '';
    } else {
      desiredDepositInput.value = parseInt(desiredDepositInput.value, 10).toLocaleString('fr-FR').replace(',', ' ');
    }
  }
}
