import { Controller } from "@hotwired/stimulus"
import i18n from "../helpers/i18n_config";

export default class extends Controller {
  static targets = ["form", "yes", "no", "multiForm", "submitButton"];
  static values = {
    isTurbo: Boolean,
    currentAccountType: String,
    currentAccountId: Number,
    creatingProperty: Boolean
  }

  connect() {
    this.formTargets.forEach((formTarget, index) => {
      const parentDiv = formTarget.closest('[data-quote-toggle-id]');
      const toggleElement = parentDiv ? parentDiv.querySelector('input[type="checkbox"]') : null;

      if (toggleElement) {
        const status = toggleElement.checked;
        formTarget.style.display = status ? "block" : "none";
        this.updateLabelClasses(status, parentDiv);
      }
    });
    this.updateButtonText();
  }

  updateButtonText() {
    let toggleSelectedCount = 0;

    for (const form of this.multiFormTargets) {
      const toggleElement = form.querySelector('input[type="checkbox"]');
      if (toggleElement && toggleElement.checked) {
        toggleSelectedCount++;
      }
    }

    const isTurbo = this.submitButtonTarget.dataset.quoteToggleIsTurboValue === 'true';

    if (isTurbo) {
      if (this.hasCreatingPropertyValue && this.creatingPropertyValue) {
        const buttonText = toggleSelectedCount > 0 ? i18n.t('quotes.new.submit_all_forms') : i18n.t('quotes.new.select_a_company');
        const isDisabled = toggleSelectedCount === 0;
        this.submitButtonTarget.textContent = buttonText;
        this.submitButtonTarget.disabled = isDisabled;
      } else {
        const buttonText = toggleSelectedCount > 0 ? i18n.t('quotes.new.submit_all_forms') : i18n.t('quotes.new.select_a_company');
        const isDisabled = toggleSelectedCount === 0;
        this.submitButtonTarget.textContent = buttonText;
        this.submitButtonTarget.disabled = isDisabled;
      }
    } else {
      if (this.hasCreatingPropertyValue && this.creatingPropertyValue) {
        const buttonText = toggleSelectedCount > 0 ? i18n.t('quotes.new.submit_all_forms') : i18n.t('quotes.new.skip_sending_quote');
        this.submitButtonTarget.textContent = buttonText;
      } else {
        const buttonText = toggleSelectedCount > 0 ? i18n.t('quotes.new.submit_all_forms') : i18n.t('quotes.new.select_a_company');
        const isDisabled = toggleSelectedCount === 0;
        this.submitButtonTarget.textContent = buttonText;
        this.submitButtonTarget.disabled = isDisabled;
      }
    }
  }

  toggle(event) {
    const status = event.target.checked;
    const parentDiv = event.target.closest('[data-quote-toggle-id]');

    if (parentDiv) {
      const formTarget = parentDiv.querySelector('[data-quote-toggle-target="form"]');

      if (formTarget) {
        formTarget.style.display = status ? "block" : "none";
      }
    }

    this.updateLabelClasses(status, parentDiv);
  }

  // toggleDivVisibility() {
  //   const toggleId = this.data.get("id");
  //   const inputElement = this.element.querySelector(`input[data-toggle-id="${toggleId}"]`);
  //   if (inputElement) {
  //     const status = inputElement.checked;
  //     this.formTarget.style.display = status ? "block" : "none";
  //     this.updateLabelClasses(status);
  //   }
  // }

  updateLabelClasses(status, parentDiv) {
    if (parentDiv) {
      const noTarget = parentDiv.querySelector('[data-quote-toggle-target="no"]');
      const yesTarget = parentDiv.querySelector('[data-quote-toggle-target="yes"]');

      if (noTarget && yesTarget) {
        if (status) {
          noTarget.classList.remove("fw-bold");
          noTarget.classList.remove("text-danger");
          yesTarget.classList.add("text-success");
          yesTarget.classList.add("fw-bold");
        } else {
          noTarget.classList.add("fw-bold");
          noTarget.classList.add("text-danger");
          yesTarget.classList.remove("text-success");
          yesTarget.classList.remove("fw-bold");
        }
      }
    }
  }

  async submitAllForms(event) {
    event.preventDefault();

    // Désactiver le bouton de soumission
    this.submitButtonTarget.disabled = true;
    this.submitButtonTarget.textContent = i18n.t('shared.please_wait');

    let toggleSelectedCount = this.multiFormTargets.reduce((count, form) => {
      const toggleElement = form.querySelector('input[type="checkbox"]');
      return count + (toggleElement && toggleElement.checked ? 1 : 0);
    }, 0);

    if (toggleSelectedCount === 0) {
      if (this.currentAccountTypeValue === "Company") {
        window.location.href = `/companies/${this.currentAccountIdValue}/inventory`;
      } else if (this.currentAccountTypeValue === "User") {
        window.location.href = `/dashboards/inventory`;
      }
      return;
    }

    let allFormsValid = true;

    this.multiFormTargets.forEach((form) => {
      const toggleElement = form.querySelector('input[type="checkbox"]');
      if (toggleElement && !toggleElement.checked) {
        return;
      }

      // Validation logic here
      const inputs = form.querySelectorAll('input[required], select[required], textarea[required]');
      inputs.forEach(input => {
        input.classList.remove('is-invalid');
        const existingErrorMsg = input.parentNode.querySelector('.text-danger');
        if (existingErrorMsg) existingErrorMsg.remove();

        if (!input.value) {
          input.classList.add('is-invalid');
          const errorMsg = document.createElement('div');
          errorMsg.textContent = i18n.t('shared.this_field_is_required');
          errorMsg.classList.add('text-danger');
          input.parentNode.appendChild(errorMsg);
          allFormsValid = false;
        }
      });

      const checkBoxes = form.querySelectorAll('input[type="checkbox"].company-checkbox');
      const checkedBoxes = Array.from(checkBoxes).filter(checkbox => checkbox.checked);
      if (checkedBoxes.length === 0) {
        let errorMsgElement = form.querySelector('.checkbox-error-msg');
        if (!errorMsgElement) {
          errorMsgElement = document.createElement('div');
          errorMsgElement.classList.add('text-danger', 'checkbox-error-msg');
          errorMsgElement.textContent = i18n.t('quotes.new.you_must_choose_at_least_one_professional');
          const checkboxContainer = form.querySelector('#company-checkbox-container') || form;
          checkboxContainer.appendChild(errorMsgElement);
        }
        allFormsValid = false;
      } else {
        const existingErrorMsg = form.querySelector('.checkbox-error-msg');
        if (existingErrorMsg) existingErrorMsg.remove();
      }
    });

    if (!allFormsValid) {
      // Réactiver le bouton en cas d'erreurs de validation
      this.submitButtonTarget.disabled = false;
      this.submitButtonTarget.textContent = i18n.t('quotes.new.submit_all_forms');
      return;
    }

    // Collect all fetch promises
    const fetchPromises = this.multiFormTargets.map(async (form) => {
      const toggleElement = form.querySelector('input[type="checkbox"]');
      if (toggleElement && toggleElement.checked) {
        const formData = new FormData(form);
        const headers = {
          'X-CSRF-Token': this.getMetaContent('csrf-token'),
          'Accept': 'text/html'
        };
        formData.append('from_html', true);

        try {
          return await fetch("/quotes", {
            method: 'POST',
            body: formData,
            headers: headers
          });
        } catch (error) {
          console.error("Error submitting form:", error);
          return null;
        }
      }
    });

    // Wait for all requests to complete
    const responses = await Promise.all(fetchPromises);

    // Check if all requests succeeded
    const allSucceeded = responses.every(response => response && response.ok);

    const flashMessage = encodeURIComponent(i18n.t('shared.your_request_has_been_sent'));
    if (allSucceeded) {
      if (this.currentAccountTypeValue === "Company") {
        window.location.href = `/companies/${this.currentAccountIdValue}/work_message?notice=${flashMessage}`;
      } else if (this.currentAccountTypeValue === "User") {
        window.location.href = `/dashboards/work_message?notice=${flashMessage}`;
      }
    } else {
      console.error("Some requests failed:", responses);
      alert(i18n.t('shared.some_requests_failed'));
    }

    // Réactiver le bouton de soumission une fois toutes les requêtes terminées
    this.submitButtonTarget.disabled = false;
    this.submitButtonTarget.textContent = i18n.t('quotes.new.submit_all_forms');
  }

  getMetaContent(name) {
    const element = document.head.querySelector(`meta[name="${name}"]`);
    return element ? element.content : null;
  }
}
